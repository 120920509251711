.CalendarYear 
{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly
}

.CalendarYear .months
{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly
}

.CalendarYear > .CalendarMonth
{
    margin: 10px;
}

.CalendarYear .day
{
    height: 30px;
    width: 30px;
    padding: 0px;
    font-size: 12px;
}


.CalendarYear .day .notes {

    height: calc(100% - 18px);
}

.CalendarYear .day .notes .marker
{
    font-size: 5px;
}


.CalendarYear .CalendarMonth.active > header h3 {
    color: var(--color-red);
}

.CalendarYear .CalendarMonth:hover  {
    cursor: pointer;
}