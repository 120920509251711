.day {
    border: 1px solid black;
    border-left: none;
    border-top: none;
    height: 100px;
    width: 100px;
    padding: 3px;
}

.day:first-of-type
{
    border-left: 1px solid black
}


.day.active {
    box-shadow: inset 0px 0px 10px #6a070a;
}

.day .notes {
    display: flex;
    flex-wrap:wrap;
    padding: 1px;
    overflow: hidden;
    max-height: calc(100% - 20px)
}

.day .notes .marker {
}

.day .notes .marker.active {
  color: var(--color-red);
    opacity: 1
}