.CalendarMonth {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.CalendarMonth header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%
}


.CalendarMonth header > *{
    margin: 5px
}


.CalendarMonth header h3{
    text-align: center;
    display:flex
}


.CalendarMonth .week 
{
    display: flex;
    width: 100%;
}

.CalendarMonth .day 
{
    cursor: pointer
}

.CalendarMonth .week:last-of-type > .day
{
    border-bottom: 1px solid black;
}

.CalendarMonth .week:first-of-type > .day
{
    border-top: 1px solid black
}

.CalendarMonth .InterCal .day 
{
    border-top: 1px solid black
}


.CalendarMonth .intercals 
{
    display: flex;
}

.CalendarMonth .InterCal 
{
    display: flex;
    flex-direction: column;
    align-items: center;
}