@import url('https://fonts.googleapis.com/css2?family=Cinzel+Decorative:wght@900&display=swap');

.Salvation {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding: 10px;
  height: 100%;
}


.Salvation h2 {
  font-family: "Cinzel Decorative";
  font-size: 60px;
  color: var(--color-red)
}

.Salvation img {
  max-width: 100%;
  max-height: 100%;
}

.salvation-menu .menu-image img,
.salvation .menu-image {
  width: 18vh;
  height: 28.8vh;
}

.salvation-menu .menu-image img{

  box-shadow: 10px 10px 8px #000000;
  cursor: pointer
}

.salvation-menu {
  display: flex;
  padding : 5vh
}

.salvation-menu .menu-image{
  flex: 1
}